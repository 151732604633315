import React from "react";
import { formInput } from "./utils.js";
import { Modal, Button, Icon, Checkbox, Grid, Form } from 'semantic-ui-react';
import axios from "axios";
import Notices from "./ProspectsEnrich/Notices.js";

export default class ProspectsEnrichModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    }

    this.handleEnrichStartClick = this.handleEnrichStartClick.bind(this);
  }

  handleEnrichStartClick() {
    this.setState({ loading: true, googleSheetUrl: null, status: null })

    let url = this.props.prospectsEnrichPath || '/prospects_enrich';

    axios.post(
      url,
      {
        ...this.props.prospectsEnrich,
        filterParams: this.props.filterParams,
      }
    ).then(({ data }) => {
      this.setState({ loading: false });
      this.props.setProspectsEnrich(data);

    })
    .catch((error) => {
      console.error(error)
      this.setState({ loading: false });
      this.props.setProspectsEnrich({ dataProviders: [], maxNumberOfProspects: 500 });
    })
  }

  render() {
    let { prospectsEnrich, setProspectsEnrich } = this.props
    prospectsEnrich ||= { dataProviders: [] };

    if (this.props.dataProviders.length == 0) return null;

    return (
      <Modal
        onClose={() => this.setState({ open: false })}
        trigger={
          this.props.trigger ?
            (<div onClick={() => this.setState({ open: true })}>{this.props.trigger}</div>) :
            (<Button size="mini" icon labelPosition='left' onClick={() => this.setState({ open: true })}><Icon name='signal' />Enrich</Button>)
        }
        open={this.state.open}
      >
        <Modal.Header>
          <Grid>
            <Grid.Column width={15}>
              Prospects Enrich
            </Grid.Column>
            <Grid.Column width={1}>
              <Icon name="close" onClick={() => this.setState({ open: false })} style={{ cursor: "pointer" }} />
            </Grid.Column>
            { !this.props.hideIndexLink && (
              <Grid.Column width={16} style={{ fontSize: 14, paddingTop: 0 }}>
                <div>
                  <a href="/prospects_enrichments">Check your running enrichments</a>
                </div>
              </Grid.Column>
            )}
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {
                  formInput(
                    prospectsEnrich,
                    "name",
                    "Name",
                    (e) => (setProspectsEnrich({ ...prospectsEnrich, name: e.target.value })),
                    Form.Input
                  )
                }
              </div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {
                  formInput(
                    prospectsEnrich,
                    "dataProviders",
                    "Data Providers",
                    (e) => (setProspectsEnrich({ ...prospectsEnrich, dataProviders: e.target.value })),
                    Form.Select,
                    {
                      options: this.props.dataProviders.map(provider => ({ key: provider.key, text: provider.name, value: provider.key })),
                      search: true,
                      multiple: true,
                    }
                  )
                }
              </div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {!this.props.hideCrmStatusSelector && (
                  formInput(
                    prospectsEnrich,
                    "crmStatusId",
                    "Set status of all enriched prospects to",
                    (e) => setProspectsEnrich({ ...prospectsEnrich, crmStatusId: e.target.value }),
                    Form.Select,
                    {
                      options: this.props.crmStatuses.filter(s => !s.hiddenAt).map(status => (
                        { key: status.id, text: status.name, value: status.id }
                      )),
                      search: true,
                      clearable: true,
                    },
                    "enrichCrmStatusId"
                  )
                )}
              </div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {
                  formInput(
                    prospectsEnrich,
                    "maxNumberOfProspects",
                    "Maximum number of prospects to enrich",
                    (e) => setProspectsEnrich({ ...prospectsEnrich, maxNumberOfProspects: e.target.value }),
                    Form.Input,
                    { type: 'number' },
                  )
                }
              </div>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Checkbox
                  label='Send export to my email when done'
                  onChange={(_, data) => setProspectsEnrich({ ...prospectsEnrich, sendEmailExport: data.checked })}
                  checked={prospectsEnrich.sendEmailExport}
                />
              </div>
              {!this.props.hideContinuousCheckbox && (
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Checkbox
                    label='Continuously enrich new prospect added to the search'
                    onChange={(_, data) => setProspectsEnrich({ ...prospectsEnrich, continuous: data.checked })}
                    checked={prospectsEnrich.continuous}
                  />
                </div>
              )}
            </Form>

            <Notices
              dataProviders={prospectsEnrich.dataProviders}
              maxNumberOfProspects={prospectsEnrich.maxNumberOfProspects}
              filterParams={this.props.filterParams}
              prospectsEnrichPath={this.props.prospectsEnrichPath}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={this.handleEnrichStartClick}
            disabled={this.state.loading}
          >
            Start Enrichment
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
