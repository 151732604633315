import React from "react";
import { Grid, Form, Divider } from "semantic-ui-react";
import { present, formInput } from "../../../shared/utils.js";

const NoProspect = ({ scheduledPhoneCall, setScheduledPhoneCall }) => {
  const displayErrors = scheduledPhoneCall.errors && present(scheduledPhoneCall.errors.cannotFindProspect)
  return (
    <>
      <Divider section horizontal>
        <div> <p style={{ textTransform: 'none', fontSize: 14 }}>No results?</p> </div>
      </Divider>
      <Form id="no-prospect" style={{ marginBottom: 20 }} error={displayErrors} >
        <Grid columns='equal'>
          {
            formInput(
              scheduledPhoneCall,
              'cannotFindProspect',
              'Prospect cannot be found in our database or on LinkedIn',
              (event) => {
                if (event.target.checked && !window.confirm("Are you sure you were not able to find this prospect in our database, or on LinkedIn?\n\nSelecting a prospect ensures this call is assigned to the call history of the correct prospect and will allow this call to show up in statistic dashboards.")) {
                  return;
                }

                setScheduledPhoneCall((prevScheduledPhoneCall) => (
                  { ...prevScheduledPhoneCall, cannotFindProspect: event.target.checked }
                ))
              },
              Form.Checkbox
            )
          }
        </Grid>
      </Form>
    </>
  )
}

export default NoProspect;
