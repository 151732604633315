import React, { useEffect } from "react";
import { Form, Button, Grid, Segment, Icon, Header, Message, Checkbox } from 'semantic-ui-react';
import FixedOptionsFormInput from "../../shared/FixedOptionsFormInput.js";
import { present, formInput } from "../../shared/utils.js";
import EditorWithTemplateVarsAndPreview from "./EditorWithTemplateVarsAndPreview.js";

const MessageForm = (props) => {
  const {
    message, followUp, firstEmail, headlineFirstMessage, index, followUpName, ourName, ourShortFirstName,
    maxConnectionMessageLength, genderedSalutes, gmailMailboxId, renderTemplateVariables, renderPreview,
    allowEditingFromStage, customPromptVariables, handleChange, manuallyRequestedLinkedInOutreach,
    ignoredMessageValidations, handleIgnoredMessageValidationsChange, errorMessagesDescriptive, errorMessagesYesNo,
    sendsEmptyInvitations, handleRemoveFollowUp, campaignId, validating
  } = props;

  let showSubject = message.sendVia === "email" && firstEmail;
  let messageChange
  if (followUp) {
    messageChange = (event, data) => { handleChange(index, event, data) }
  } else {
    messageChange = handleChange
  }

  let ignoredValidations

  if (ignoredMessageValidations) {
    if (followUp) {
      ignoredValidations = ignoredMessageValidations?.find(i => i.type === 'followUp' && i.index === index && i.addressBook === sendsEmptyInvitations)
    } else {
      ignoredValidations = ignoredMessageValidations?.find(i => i.type === 'message' && i.addressBook === sendsEmptyInvitations)
    }
    if (!ignoredValidations) {
      ignoredValidations = {
        type: followUp ? 'followUp' : 'message',
        index: followUp ? index : null,
        addressBook: sendsEmptyInvitations,
      }
      ignoredMessageValidations.push(ignoredValidations)
    }
  } else {
    ignoredValidations ||= {}
  }

  useEffect(() => {
    if (validating || manuallyRequestedLinkedInOutreach) return;

    // Every time after validation we check if the variable validation errors list still contains the ignored validations
    // 1. User ignores some validation
    // 2. User changes the message so the validation error does not apply anymore
    // 3. The message is changed to again contain the validation error
    // In such scenario the checkbox would be automatically checked again, which is against the purpose of explicilty
    // asking users to ignore each validation by clicking on the checkbox
    const usedIgnoredValidations = {
      type: ignoredValidations.type, index: ignoredValidations.index, addressBook: ignoredValidations.addressBook
    }

    variableValidationErrors.forEach(e => {
      let [validationName, value] = e

      if (Array.isArray(ignoredValidations[validationName])) {
        usedIgnoredValidations[validationName] ||= []
        if (ignoredValidations[validationName].includes(value.matchString)) {
          usedIgnoredValidations[validationName].push(value.matchString)
        }
      } else {
        usedIgnoredValidations[validationName] = ignoredValidations[validationName]
      }
    })

    let ignoredValidationIndex = ignoredMessageValidations.findIndex(i =>
      i.type === usedIgnoredValidations.type && i.index === usedIgnoredValidations.index && i.addressBook === usedIgnoredValidations.addressBook
    )
    ignoredMessageValidations[ignoredValidationIndex] = usedIgnoredValidations
    handleIgnoredMessageValidationsChange(ignoredMessageValidations)
  }, [validating])

  const changeIgnoredMessageValidations = (error, variable = null, checked) => {
    if (variable) {
      ignoredValidations[error] ||= []
      if (checked) {
        ignoredValidations[error].push(variable)
      } else {
        ignoredValidations[error] = ignoredValidations[error].filter(v => v !== variable)
      }
    } else {
      ignoredValidations[error] = checked
    }
    handleIgnoredMessageValidationsChange(ignoredMessageValidations)
  }

  const errors = message.errors || {};

  const variableValidationErrors = (errors.message || []).map(e => {
    if (typeof e === "string") return

    let [key, value] = Object.entries(e)[0]

    return [key, value]
  }).filter(e => e)

  const allErrorsAcknowledged = variableValidationErrors.every(e => {
    let [validationName, value] = e

    if (Array.isArray(ignoredValidations[validationName])) {
      return ignoredValidations[validationName].includes(value.matchString)
    } else {
      return ignoredValidations[validationName]
    }
  })

  return (
    <div key={index} style={{ marginBottom: 14 }} data-test-id={followUp ? ("follow-up-" + index) : "connection-message"}>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12} floated="left">
              <Header as={"h3"}>
                {followUp ? (`${followUpName || "Follow up"} ${index + 1}`) : (headlineFirstMessage || "Connection Message")}
              </Header>

            </Grid.Column>

            {followUp && (
              <Grid.Column width={2} floated="right" style={{ textAlign: "right" }}>
                <Button type="button" onClick={() => { handleRemoveFollowUp(index) }} icon><Icon name="trash" /></Button>
              </Grid.Column>
            )}

            {!followUp && manuallyRequestedLinkedInOutreach && (
              <Grid.Column width={3}>
                <Header as={"h3"} textAlign="right">
                  {console.log('campaign id is', campaignId)}
                  <Button
                    size="tiny"
                    onClick={() => props.prefillMessages()}
                    disabled={!present(campaignId)}
                  >
                    Auto-fill campaign messages
                  </Button>
                </Header>
              </Grid.Column>
            )}
          </Grid.Row>

          {followUp && (
            <>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <FixedOptionsFormInput
                    model={{ ...message, daysDelay: "" + message.daysDelay }}
                    name="daysDelay"
                    label="When"
                    onChange={messageChange}
                    Element={Form.Select}
                    props={
                      {
                        options: [
                          {key: "0", value: "0", text: "as soon as possible", disabled: sendsEmptyInvitations || index > 0},
                          {key: "1", value: "1", text: "after one day", },
                          ...(_.range(2,90).map(i => { return {key: ""+i, value: ""+i, text: `after ${i} days`, } }))
                        ],
                      }
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <FixedOptionsFormInput
                    model={{ ...message }}
                      name="sendVia"
                      label="Send as..."
                      onChange={messageChange}
                      Element={Form.Select}
                      props={
                        {
                          options: [
                            {key: "linkedin", value: "linkedin", text: "LinkedIn message"},
                            {key: "email", value: "email", text: "Email", },
                          ],
                        }
                      }
                    />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  { message.sendVia === "email" && showSubject &&
                      formInput(
                        { ...message, daysDelay: "" + message.daysDelay },
                        "subject",
                        "Subject",
                        messageChange,
                      )
                  }
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
        <EditorWithTemplateVarsAndPreview
          ourName={ourName}
          ourShortFirstName={ourShortFirstName}
          asPlaintext={followUp ? message.sendVia === "linkedin" : true}
          name={"message"}
          model={message}
          labelContent={"Message"}
          onChange={messageChange}
          style={{ minHeight: 300 }}
          maxChars={maxConnectionMessageLength}
          genderedSalutes={genderedSalutes}
          gmailMailboxId={gmailMailboxId}
          renderTemplateVariables={renderTemplateVariables}
          renderPreview={renderPreview}
          editable={allowEditingFromStage <= index+1}
          customPromptVariables={customPromptVariables}
          followUp={followUp}
          highlightErrors={variableValidationErrors.length > 0}
          errorMessages={errorMessagesDescriptive}
          ignoredMessageValidations={ignoredValidations}
          errors={variableValidationErrors}
        />
        { present(message.messages) && message.messages.length > 0 && <span>{message.messages.join("; ")}</span> }
        {variableValidationErrors.length > 0 && (
          <Message negative={!allErrorsAcknowledged}>
            <b>
              We've detected problems with your message, please correct the issues or tick the checkbox if you want to ignore:
            </b>

            {variableValidationErrors.map((error, i) => {
              if (typeof error === "string") return;

              const [err, variable] = error

              return (
                <div key={i} style={{ marginTop: 10, marginBottom: 10 }}>
                  {err === "firstLine" && (
                    <Checkbox
                      name="first_line"
                      label={errorMessagesYesNo.firstLine}
                      onChange={(_, data) => changeIgnoredMessageValidations("firstLine", null, data.checked)}
                      checked={ignoredValidations?.firstLine}
                    />
                  )}
                  {err === "unrecognizedVariable" && (
                    <Checkbox
                      name={`unrecognized_variable_${variable.matchString}`}
                      label={errorMessagesYesNo.unrecognizedVariable.replaceAll('#variable#', variable.matchString)}
                      onChange={(_, data) => changeIgnoredMessageValidations("unrecognizedVariable", variable.matchString, data.checked)}
                      checked={ignoredValidations?.unrecognizedVariable?.includes(variable.matchString)}
                    />
                  )}
                  {err === "lastLineProspectName" && (
                    <Checkbox
                      name="last_line_prospect_name"
                      label={errorMessagesYesNo.lastLineProspectName}
                      onChange={(_, data) => changeIgnoredMessageValidations("lastLineProspectName", null, data.checked)}
                      checked={ignoredValidations?.lastLineProspectName}
                    />
                  )}
                  {err === "lastLineMissingOurName" && (
                    <Checkbox
                      name="last_line_missing_our_name"
                      label={errorMessagesYesNo.lastLineMissingOurName}
                      onChange={(_, data) => changeIgnoredMessageValidations("lastLineMissingOurName", null, data.checked)}
                      checked={ignoredValidations?.lastLineMissingOurName}
                    />
                  )}
                  {err === "lastLineDirectName" && (
                    <Checkbox
                      name="last_line_direct_name"
                      label={errorMessagesYesNo.lastLineDirectName.replaceAll('#variable#', variable)}
                      onChange={(_, data) => changeIgnoredMessageValidations("lastLineDirectName", null, data.checked)}
                      checked={ignoredValidations?.lastLineDirectName}
                    />
                  )}
                  {err === "genderedSaluteDirectlyIncluded" && (
                    <Checkbox
                      name="gendered_salute_directly_included"
                      label={errorMessagesYesNo.genderedSaluteDirectlyIncluded.replace('#value#', variable.matchString).replace('#variable#', variable.correctVariable)}
                      onChange={(_, data) => changeIgnoredMessageValidations("genderedSaluteDirectlyIncluded", variable.matchString, data.checked)}
                      checked={ignoredValidations?.genderedSaluteDirectlyIncluded?.includes(variable.matchString)}
                    />
                  )}
                </div>
              )
            })}
          </Message>
        )}
      </Segment>
    </div>
  );
}

export default MessageForm;
