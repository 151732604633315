import React from "react";
import { Header, Table, Form, Button, Icon, Modal, Grid, } from 'semantic-ui-react'
import { present, formInput, } from "./utils.js";
import axios from "axios";
import _ from "underscore"


export default class ProspectCrmStatusChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crmStatusChangeActive: false,
      crmStatusChange: {},
    }
  }

  onCrmStatusChange(event) {
    let prospect = {...this.props.prospect};
    let change = {
      id: prospect.id,
      crmStatusId: event.target.value,
      triggeredByCampaignId: this.guessBestTrigger(prospect.prospectCampaignAssociations),
    }
    if (this.crmStatusIsTriggeredByCampaign(change.crmStatusId) && prospect.prospectCampaignAssociations.length > 1) {
      this.setState({ crmStatusChangeActive: true, crmStatusChange: change})
    } else {
      this.saveCrmStatusChange(change);
    }
  }

  guessBestTrigger(assocs) {
    assocs = _.sortBy(assocs, assoc => assoc.linkedInOutreach.repliedAt)
    return present(assocs[assocs.length-1]) ? assocs[assocs.length-1].campaign.id : null;
  }

  crmStatusIsTriggeredByCampaign(crmStatusId) {
    if (!present(crmStatusId)) return false
    return this.props.crmStatuses.find(s => s.id === crmStatusId).triggeredByCampaign === true;
  }

  saveCrmStatusChange(change) {
    let { prospect } = this.props
    axios.post(`/prospects/${prospect.id}/crm_status_change`, change)
      .then(({data}) => {
        console.log("crm_status_change success");
        console.log(data);
        this.props.onProspectChange(data);
      })
    this.props.onProspectChange({ ...prospect, crmStatusId: change.crmStatusId, });
  }

  onClose() {
    console.log("on close triggered");
    this.setState({ crmStatusChangeActive: false })
  }

  onCrmStatusChangeChange(event) {
    console.log("oncrmstatuschangechange", event);
    let toChange = { ...this.state.crmStatusChange };
    toChange[event.target.name] = event.target.value
    this.setState({ crmStatusChange: toChange })
  }

  onCrmStatusChangeSubmit() {
    this.saveCrmStatusChange(this.state.crmStatusChange)
    this.setState({ crmStatusChangeActive: false })
  }

  render () {
    let { crmStatusChange, } = this.state;
    let { prospect, crmStatuses, users, } = this.props;

    return (
      <React.Fragment>


        <Modal trigger={null} open={this.state.crmStatusChangeActive}>
          <Modal.Header>
            <Grid>
              <Grid.Column width={15}>
                { "Select campaign responsible for status change" }
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon name="close" onClick={this.onClose.bind(this)} style={{cursor: "pointer"}}/>
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Grid columns='equal'>
                <Grid.Column width={16}>
                  {
                    formInput(
                      crmStatusChange,
                      "triggeredByCampaignId",
                      "Responsible campaign",
                      this.onCrmStatusChangeChange.bind(this),
                      Form.Select,
                      {
                        options: prospect.prospectCampaignAssociations.map(a => a.campaign).map(c => {
                          return {
                            key: c.id,
                            value: c.id,
                            text: c.name,
                          }
                        }),
                        clearable: true,
                      },
                    )
                  }
                </Grid.Column>
                <Grid.Column width={16}>
                  <Button.Group>
                    <Button
                      loading={false}
                      onClick={this.onClose.bind(this)}
                    >Cancel</Button>
                    <Button.Or />
                    <Button
                      positive
                      onClick={this.onCrmStatusChangeSubmit.bind(this)}
                      loading={false}
                    >Save &amp; Close</Button>
                  </Button.Group>
                </Grid.Column>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>

        {
          formInput(
            prospect,
            "crmStatusId",
            present(this.props.label) ? this.props.label : "",
            this.onCrmStatusChange.bind(this),
            Form.Select,
            {
              options: crmStatuses.filter(s => !s.hiddenAt || s.id == prospect.crmStatusId).map(s => {
                return {
                  key: s.id,
                  value: s.id,
                  text: s.name,
                }
              }),
              text: `${prospect?.crmStatusId && crmStatuses.find(o => o.id === prospect.crmStatusId).name || " "}`,
              clearable: true,
              selectOnNavigation: false,
              selectOnBlur: false,
            },
          )
        }
      </React.Fragment>

    )
  }
}
