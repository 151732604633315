import React from 'react'
import { Message } from 'semantic-ui-react'
import { present } from "../../../shared/utils.js";
import moment from 'moment';

const IncomingCallsSinceLastScheduledPhoneCall = ({ scheduledPhoneCall, outcomes, setDisplayPrevious, setHightlightedScheduledPhoneCall }) => {
  if (!present(scheduledPhoneCall.phoneCallCampaignGroupAction)) return null;

  let incomingCallsInBetween
  if (scheduledPhoneCall.scheduledPhoneCalls) {
    const spcs = scheduledPhoneCall.scheduledPhoneCalls
    let previousScheduledPhoneCall = spcs.find(s => s.id == scheduledPhoneCall.createdById)

    if (!previousScheduledPhoneCall) return null

    incomingCallsInBetween = spcs.filter(s => {
      if (s.createdViaIncomingCall != true) return false

      return moment(s.createdAt).isBetween(previousScheduledPhoneCall.completedAt, scheduledPhoneCall.scheduledFor)
    })
  }

  if (incomingCallsInBetween.length == 0) return null

  const openAndHighlightCall = (incomingCall) => {
    setDisplayPrevious(true)
    setHightlightedScheduledPhoneCall(incomingCall.id)
    setTimeout(() => setHightlightedScheduledPhoneCall(null), 1500)
  }

  return incomingCallsInBetween.map(incomingCall => {
    let outcome = outcomes.find(o => o.id == incomingCall.phoneCallOutcomeId)
    return (
      <Message info>
        <Message.Content>
          There was an incoming call by this person that was {incomingCall.sourcePhoneCall.pickedUpAt ? (
            <>
              was picked up by {incomingCall.sourcePhoneCall.user.name} {outcome ? `with outcome "${outcome?.name}"` : 'without outcome'}.
            </>
          ) : (<>was not picked up by us.</>)}
          &nbsp;<a href="#" onClick={() => openAndHighlightCall(incomingCall)}>View</a>
        </Message.Content>
      </Message>
    )
  })
}

export default IncomingCallsSinceLastScheduledPhoneCall
