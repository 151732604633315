/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("fomantic-ui-less/semantic.less");
require("@fortawesome/fontawesome-free/css/all.css");
require("../stylesheets/frontend.scss");

import { Icon, Popup } from 'semantic-ui-react';
import CampaignsDashboard from "../shared/CampaignsDashboard.js";
import ProspectsDashboard from "../shared/ProspectsDashboard.js";
import CreditsProgress from "../components/CreditsProgress";
import ReactDOM from "react-dom";
import React from "react";
import $ from "jquery";
import SearchDetail from "../backend/searches/SearchDetail.js";
import CrmTasksDashboard from "../shared/CrmTasksDashboard.js";
import FunnelStatistics from "../shared/FunnelStatistics.js";
import CompanyStatistics from "../shared/CompanyStatistics.js";
import ColdCallingDashboard from '../frontend/ColdCalling/ColdCallingDashboard.js';

import initTracking from "../shared/activity_tracking.js";

window.$ = $;
window.jQuery = $;
require("fomantic-ui-less/definitions/modules/tab");
require("fomantic-ui-less/definitions/modules/transition");
require("fomantic-ui-less/definitions/modules/dropdown");

document.addEventListener("DOMContentLoaded", () => {
  const reactNodes = document.querySelectorAll("[data-react-component]");

  reactNodes.forEach((node) => {
    const reactProps = node.getAttribute("data-react-props") || "{}";
    const reactComponent = node.getAttribute("data-react-component");

    import(`./../components/${reactComponent}`).then(
      ({ default: Component }) => {
        node.removeAttribute("data-react-props");
        node.removeAttribute("data-react-component");

        ReactDOM.render(<Component {...JSON.parse(reactProps)} />, node);
      }
    );
  });

  if (!document.location.host.match(/localhost/)) {
    initTracking({ sendTimeoutOverwrite: window.TEST ? 1 : null });
  }
});

document.addEventListener("DOMContentLoaded", () => {
  $(".non-react.ui.dropdown").each(function() {
    $(this).dropdown({
      allowAdditions: false,
      action: $(this).hasClass("action-nothing") ? "nothing" : "activate",
    });
  });
  $(".auto-submit").change(function () {
    $(this).submit();
  });
});

document.addEventListener("DOMContentLoaded", () => {
  let campaignsDiv = document.getElementById("campaigns");
  if (campaignsDiv) {
    ReactDOM.render(
      <CampaignsDashboard
        initialCampaigns={$(campaignsDiv).data("campaigns")}
        canEdit={$(campaignsDiv).data("can-edit")}
        context={$(campaignsDiv).data("context")}
        canSeeDetails={$(campaignsDiv).data("can-see-details")}
        canSeeColorCode={$(campaignsDiv).data("can-see-color-code")}
        canUseFilmMode={$(campaignsDiv).data("can-use-film-code")}
        cleanStyle={$(campaignsDiv).data("should-display-clean-style")}
        showConversion={$(campaignsDiv).data("should-show-conversion")}
      />,
      campaignsDiv
    );
  }

  let searchDiv = document.getElementById("search");
  if (searchDiv) {
    ReactDOM.render(
      <SearchDetail
       search={$(searchDiv).data("search")}
       showConversion={$(searchDiv).data("should-show-conversion")}
      />,
      searchDiv
    );
  }

  let frontendCreditsDiv = document.getElementById("frontendCredits");
  if (frontendCreditsDiv) {
    ReactDOM.render(
      <CreditsProgress
        totalCredits={$(frontendCreditsDiv).data("total")}
        usedCredits={$(frontendCreditsDiv).data("used")}
      />,
      frontendCreditsDiv
    );
  }

  let prospectsDiv = document.getElementById("prospects");
  if (prospectsDiv) {
    ReactDOM.render(
      <ProspectsDashboard
        crmStatuses={$(prospectsDiv).data("crm-statuses")}
        campaigns={$(prospectsDiv).data("campaigns")}
        users={$(prospectsDiv).data("users")}
        tags={$(prospectsDiv).data("tags")}
        companyId={$(prospectsDiv).data("company-id")}
        prospectsEnrichments={$(prospectsDiv).data("prospects-enrichments")}
        currentUserId={$(prospectsDiv).data("current-user-id")}
        currentUserCanSeeProspectDetails={$(prospectsDiv).data("current-user-can-see-prospect-details")}
        currentUserCanTriggerDataProviderEnrichment={$(prospectsDiv).data("current-user-can-trigger-data-provider-enrichment")}
        currentUserCanSeeDataProviderData={$(prospectsDiv).data("current-user-can-see-data-provider-data")}
        currentUserTwilioPhoneNumber={$(prospectsDiv).data("current-user-twilio-phone-number")}
        currentAdminId={$(prospectsDiv).data("current-admin-id")}
        dataProviders={$(prospectsDiv).data("data-providers")}
      />,
      prospectsDiv
    );
  }

  let crmTasksDiv = document.getElementById("crmTasks");
  if (crmTasksDiv) {
    ReactDOM.render(
      <CrmTasksDashboard
        users={$(crmTasksDiv).data("users")}
        currentUserId={$(crmTasksDiv).data("current-user-id")}
        crmStatuses={$(crmTasksDiv).data("crm-statuses")}
        campaigns={$(crmTasksDiv).data("campaigns")}
        tags={$(crmTasksDiv).data("tags")}
        currentUserCanSeeProspectDetails={$(crmTasksDiv).data("current-user-can-see-prospect-details")}
      />,
      crmTasksDiv
    );
  }

  let funnelStatisticsDiv = document.getElementById("funnelStatistics");
  if (funnelStatisticsDiv) {
    ReactDOM.render(
      <FunnelStatistics
        funnelStatisticSets={$(funnelStatisticsDiv).data(
          "funnel-statistic-sets"
        )}
        crmStatuses={$(funnelStatisticsDiv).data("crm-statuses")}
        campaigns={$(funnelStatisticsDiv).data("campaigns")}
        users={$(funnelStatisticsDiv).data("users")}
      />,
      funnelStatisticsDiv
    );
  }

  const companyStatisticsDiv = document.getElementById("companyStatistics");
  if (companyStatisticsDiv) {
    ReactDOM.render(
      <CompanyStatistics
        company={$(companyStatisticsDiv).data("company")}
        showConversion={true}
      />,
      companyStatisticsDiv
    );
  }

  const adminRequiredAlertDiv = document.getElementById("adminRequiredAlert");
  if (adminRequiredAlertDiv) {
    ReactDOM.render(
      <Popup
        hoverable
        position="center"
        content={
          <>
            You must be logged in as an admin to switch between users, <a href='/admins/sign_in' target='_blank'>click to login</a> as admin.
          </>
        }
        trigger={<Icon name="warning circle" color="red" />}
      />,
      adminRequiredAlertDiv
    );
  }

  const coldCallingDiv = document.getElementById("coldCalling");
  if (coldCallingDiv) {
    ReactDOM.render(
      <ColdCallingDashboard
        users={$(coldCallingDiv).data("users")}
        campaigns={$(coldCallingDiv).data("campaigns")}
        crmStatuses={$(coldCallingDiv).data("crm-statuses")}
        currentUserTwilioPhoneNumber={$(prospectsDiv).data("current-user-twilio-phone-number")}
        phoneCallOutcomes={$(coldCallingDiv).data("phone-call-outcomes")}
        campaignGroups={$(coldCallingDiv).data("campaign-groups") || []}
        scheduledPhoneCall={$(coldCallingDiv).data("scheduled-phone-call")}
        prospectDistributionRunning={$(coldCallingDiv).data("prospect-distribution-running")}
        tags={$(coldCallingDiv).data("tags")}
      />,
      coldCallingDiv
    );
  }
});

document.addEventListener("DOMContentLoaded", () => {
  $("body").on("click", "table.sortable th", function (event) {
    if (event.target === this) {
      window.location = $(this).children("a")[0].href;
    }
  });
});
