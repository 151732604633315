import React from "react";
import { Form, Loader, Popup } from 'semantic-ui-react';
import axios from 'axios';
import { present } from "../../shared/utils.js";
import TestMail from "./TestMail.js";

export default class MessagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: "",
      charCount: 0,
      loading: true,
      error: null,
    }
    this.debouncedLoadPreview = _.debounce(this.loadPreview.bind(this), 100)
  }

  componentDidMount() {
    this.debouncedLoadPreview();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.template !== this.props.template) {
      this.debouncedLoadPreview();
    }
  }

  loadPreview() {
    if (this.lastCancelSource) { this.lastCancelSource.cancel(); }

    if (!present(this.props.template)) {
      this.setState({
        preview: "",
        charCount: 0,
        error: null,
        loading: false,
      });
      return;
    }

    this.lastCancelSource = axios.CancelToken.source();

    this.setState({ loading: true })
    axios.post("/api/populate_message/", {
      message: this.props.template,
      our_name: this.props.ourName,
      our_short_first_name: this.props.ourShortFirstName,
      data: {
        name: "Magdalena Musterfrau",
      },
    }, {
      cancelToken: this.lastCancelSource.token,
    })
      .then(({ data }) => {
        this.setState({
          loading: false,
          preview: data.message,
          charCount: data.charCount,
          error: null,
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log("canceled");
          return;
        }
        console.log(error);
        this.setState({
          loading: false,
          error: error.message,
        })
      });
  }

  render() {
    let { errors, highlightErrors, errorMessages, ignoredMessageValidations } = this.props;

    let previewWithHiglights = this.state.preview
    if (highlightErrors && present(errors)) {
      const errorsRegexMessages = errors.map((e) => {
        // Skip the non-object errors
        if (typeof e === "string") return;

        const [errorMessage, errorDetails] = e
        let textToChange
        if (typeof errorDetails === "string") {
          textToChange = errorDetails
        } else if (typeof errorDetails === "object") {
          textToChange = errorDetails.matchString
        } else if (typeof errorDetails !== "boolean") {
          throw new Error("Unknown format of error details")
        }

        if (!present(ignoredMessageValidations)) return [textToChange, errorMessages[errorMessage]];

        // Disable highlighting when checkbox is checked
        if (Array.isArray(ignoredMessageValidations[errorMessage])) {
          if (ignoredMessageValidations[errorMessage].includes(textToChange)) return;
        } else {
          if (ignoredMessageValidations[errorMessage]) return;
        }
        // For some errors we do not match any specific text, so we return the error message as is and handle it while rendering lines
        if (typeof errorDetails === "boolean") {
          return [errorMessage, errorMessages[errorMessage]]
        }

        return [textToChange, errorMessages[errorMessage].replaceAll('#variable#', errorDetails?.correctVariable)]
      }).filter(x => x)

      const errorMap = Object.fromEntries(errorsRegexMessages)
      const lines = this.state.preview.trimEnd().split('\n');
      previewWithHiglights = lines.map((line, i) => {
        if (i === 0 && Object.keys(errorMap).includes('firstLine')) {
          return <Popup
            key={i}
            trigger={
              <span style={{ backgroundColor: 'rgb(255 4 0 / 60%)', padding: '2px', borderRadius: '4px' }}>
                {line}
                {this.props.asPlaintext && <br></br>}
              </span>
            }
            content={errorMessages['firstLine']}
          />
        }

        if (i === lines.length - 1 && Object.keys(errorMap).includes('lastLineProspectName')) {
          return <Popup
            key={i}
            trigger={
              <span style={{ backgroundColor: 'rgb(255 4 0 / 60%)', padding: '2px', borderRadius: '4px' }}>
                {line}
              </span>
            }
            content={errorMessages['lastLineProspectName']}
          />
        }

        if (i === lines.length - 1 && Object.keys(errorMap).includes('lastLineMissingOurName')) {
          return <Popup
            key={i}
            trigger={
              <span style={{ backgroundColor: 'rgb(255 4 0 / 60%)', padding: '2px', borderRadius: '4px' }}>
                {line}
              </span>
            }
            content={errorMessages['lastLineMissingOurName']}
          />
        }

        let toHighlight = Object.keys(errorMap).filter(k => !Object.keys(errorMessages).includes(k))
        let parts = [line]
        if (toHighlight.length > 0) {
          const regex = new RegExp(`(${Object.keys(errorMap).filter(k => !Object.keys(errorMessages).includes(k)).join("|")})`, "gi");
          parts = line.split(regex)
        }

        return (
          <span key={i} style={{ whiteSpace: this.props.asPlaintext && "pre-wrap" }}>
            {parts.map((part, index) => {
              if (errorMap[part]) {
                return (
                  <Popup
                    key={`${index}-${i}`}
                    trigger={<span style={{ backgroundColor: 'rgb(255 4 0 / 60%)', padding: '2px', borderRadius: '4px' }}>{part}</span>}
                    content={errorMap[part]}
                  />
                );
              }
              if (this.props.asPlaintext) {
                return <span style={{ whiteSpace: "pre-wrap" }} key={`${index}-${i}`}>{part}</span>;
              } else {
                return <span dangerouslySetInnerHTML={{ __html: part + ' ' }} key={`${index}-${i}`} />;
              }
            })}
            {this.props.asPlaintext && <br></br>}
          </span>
        )
      })
    }

    return (
      <div style={{ position: "relative" }}>
        <Form.Field>
          <label>
            Preview ({this.state.charCount})&nbsp;&nbsp;
            <Loader size='mini' className="showAfterOneSecond" active={this.state.loading} inline />
          </label>
          {highlightErrors && previewWithHiglights}
          {!highlightErrors && this.props.asPlaintext && <div style={{ whiteSpace: "pre-wrap" }}>{this.state.preview}</div>}
          {!highlightErrors && !this.props.asPlaintext && <div dangerouslySetInnerHTML={{ __html: this.state.preview }} />}
        </Form.Field>
        {!this.props.asPlaintext && <div style={{ position: "absolute", top: 0, right: 0 }}>
          <TestMail
            subject={this.props.subject}
            message={this.props.template}
            gmailMailboxId={this.props.gmailMailboxId}
          />
        </div>}
      </div>
    );
  }
}
