import React from 'react';
import { Form, Button, Message, Header, Popup, Input } from 'semantic-ui-react';
import { present } from "../../shared/utils.js";
import axios from 'axios';

export default class TestMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      to: "",
      loading: false,
      firstRequestDone: false,
      error: null,
    };
    this.sendTestEmail = this.sendTestEmail.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }

  handleEmailChange(_event, { value }) {
    this.setState({to: value});
  }

  sendTestEmail() {
    this.setState({loading: true, error: null, })
    axios.post(`/backend/gmail_mailboxes/${this.props.gmailMailboxId}/test_email/`, {
      message: this.props.message,
      subject: this.props.subject,
      to: this.state.to,
      data: {
        name: "Magdalena Musterfrau",
      },
    })
    .then(({data}) => {
      this.setState({
        loading: false,
        error: data.error,
        success: true,
        firstRequestDone: true,
      });
    })
    .catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        error: error.message,
        firstRequestDone: true,
      })
    });
  }

  render() {
    return (
      <Popup trigger={<Button size="mini">Test Mail</Button>} on="click">
        { !present(this.props.gmailMailboxId) && <Message>Please select mailbox for this campaign.</Message> }
        { present(this.props.gmailMailboxId) && <React.Fragment>
            <Header as='h5'>Send Test Mail</Header>
            { this.state.error && <Message negative><p>{this.state.error}</p></Message> }
            { this.state.firstRequestDone && !this.state.error && !this.state.loading && <Message success>Message sent</Message> }
            <Form onSubmit={this.sendTestEmail}>
              <Input type="text" action placeholder="Email..." value={this.state.to} onChange={this.handleEmailChange} style={{flex: 1}}>
                <input style={{flex:1 }} />
                <Button type="submit" loading={this.state.loading}>Send</Button>
              </Input>
            </Form>
          </React.Fragment>
        }
      </Popup>
    );
  }
}
