import React from "react";
import { Button, } from 'semantic-ui-react';
import _ from "underscore"
import MessageForm from "./CampaignMessagesForm/MessageForm.js";

const ERROR_MESSAGES = {
  firstLine: "It's best to include the prospect's name in the first line for a personal touch. Use #firstName# or #lastName# to do this automatically.",
  unrecognizedVariable: "Unrecognized variable detected. It won't be replaced with any value.",
  lastLineProspectName: "Your message ends with '#firstName#' or '#lastName#', which will be replaced with the prospect's name. Use #ourFirstName# or #ourLastName# instead to sign off with your name.",
  lastLineMissingOurName: "Your message should end with your name using #ourFirstName# or #ourLastName#.",
  lastLineDirectName: "You're using a specific LinkedIn account name. If you add another account later, the wrong name may be used for signing off.",
  genderedSaluteDirectlyIncluded: "This is a gendered salutation and won't adjust based on the prospect's gender. Replace it with #variable# for automatic replacement.",
};

const ERROR_MESSAGES_YES_NO = {
  firstLine: "Yes, I want to save the message without using #firstName# or #lastName# to personalize the first line with Prospect name.",
  unrecognizedVariable: "Yes, I want to save the message containing '#variable#'. I understand this is not a recognized variable and will appear exactly as written: #variable#",
  lastLineProspectName: "Yes, I understand my message will be signed off with the prospect's name instead of mine.",
  lastLineMissingOurName: "Yes, I understand my message does not end with my LinkedIn account name ('#ourFirstName#' or '#ourLastName#'). If another LinkedIn account is added to this campaign, the wrong name may be used.",
  lastLineDirectName: "Yes, I understand my message ends with a fixed name instead of a variable: #variable#.",
  genderedSaluteDirectlyIncluded: "Yes, I understand my message contains a gendered salutation ('#value#'), which won't change based on the prospect's gender. Should be replaced with #variable#.",
};


export default class CampaignMessagesForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      messageWithFocus: null,
    }
    this.messageRefs = {};
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.handleEmptyInvitationsChange = this.handleEmptyInvitationsChange.bind(this)
  }

  componentDidMount(_prevProps, _prevState) {
    this.handleEmptyInvitationsChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sendsEmptyInvitations !== this.props.sendsEmptyInvitations) {
      this.handleEmptyInvitationsChange();
    }
  }

  handleEmptyInvitationsChange() {
    let newFollowUps = [...this.props.messages.followUps]

    if (this.props.sendsEmptyInvitations && newFollowUps[0]?.daysDelay === "0") {
      newFollowUps[0].daysDelay = "2";
    }

    this.props.onMessagesChange({ message: this.props.messages.message, followUps: newFollowUps });
  }

  handleAddFollowUp() {
    let newFollowUps = [...this.props.messages.followUps]
    newFollowUps.push({
      message: "",
      daysDelay: this.defaultDaysDelay(newFollowUps),
      sendVia: "linkedin",
    });
    this.props.onMessagesChange({ message: this.props.messages.message, followUps: newFollowUps });
  }

  defaultDaysDelay(newFollowUps) {
    let l = newFollowUps.length

    if (this.props.sendsEmptyInvitations && l === 0) {
      return "2";
    }
    if (!this.props.sendsEmptyInvitations && l === 0) {
      return "0";
    }
    if (l > 0) {
      return "14"
    }
  }

  handleRemoveFollowUp(removeIndex) {
    let newFollowUps = [...this.props.messages.followUps]
    newFollowUps = this.props.messages.followUps.filter((x, i) => i !== removeIndex)
    this.props.onMessagesChange({ message: this.props.messages.message, followUps: newFollowUps });
  }

  handleFollowUpsChange(index, event, data) {
    let newFollowUps = [...this.props.messages.followUps]
    newFollowUps[index][event.target.name] = event.target.value;
    this.props.onMessagesChange({ message: this.props.messages.message, followUps: newFollowUps });
  }

  handleMessageChange(event) {
    this.props.onMessagesChange({ ...this.props.messages, message: event.target.value, });
  }

  handleIgnoredMessageValidationsChange(ignoredMessageValidations) {
    this.props.onIgnoredMessageValidationsChange(ignoredMessageValidations, false);
  }

  renderFollowUps() {
    let { messages, followUpName, } = this.props;

    return messages.followUps.map((followUp, i) =>
      <MessageForm
        message={followUp}
        followUp={true}
        firstEmail={followUp.sendVia === "email" && messages.followUps.slice(0, i).filter(f => f.sendVia === "email").length === 0}
        followUpName={followUpName}
        handleChange={this.handleFollowUpsChange.bind(this)}
        sendsEmptyInvitations={this.props.sendsEmptyInvitations}
        ourName={this.props.ourName}
        ourShortFirstName={this.props.ourShortFirstName}
        maxConnectionMessageLength={this.props.maxConnectionMessageLength}
        genderedSalutes={this.props.genderedSalutes}
        gmailMailboxId={this.props.gmailMailboxId}
        renderTemplateVariables={this.props.renderTemplateVariables}
        renderPreview={this.props.renderPreview}
        allowEditingFromStage={this.props.allowEditingFromStage}
        customPromptVariables={this.props.customPromptVariables}
        errorMessagesDescriptive={ERROR_MESSAGES}
        errorMessagesYesNo={ERROR_MESSAGES_YES_NO}
        manuallyRequestedLinkedInOutreach={this.props.manuallyRequestedLinkedInOutreach}
        ignoredMessageValidations={this.props.ignoredMessageValidations}
        handleIgnoredMessageValidationsChange={this.handleIgnoredMessageValidationsChange.bind(this)}
        handleRemoveFollowUp={this.handleRemoveFollowUp.bind(this)}
        campaignId={messages.campaignId}
        prefillMessages={this.props.prefillMessages}
        validating={this.props.validating}
        index={i}
        key={i}
      />
    );
  }

  render() {
    let { messages, genderedSalutes, headlineFirstMessage, } = this.props;

    return (
      <div>
        <MessageForm
          message={messages}
          followUp={false}
          firstEmail={false}
          headlineFirstMessage={headlineFirstMessage}
          followUpName={this.props.followUpName}
          handleTextChange={this.handleMessageChange}
          handleChange={this.handleMessageChange.bind(this)}
          sendsEmptyInvitations={this.props.sendsEmptyInvitations}
          ourName={this.props.ourName}
          ourShortFirstName={this.props.ourShortFirstName}
          maxConnectionMessageLength={this.props.maxConnectionMessageLength}
          genderedSalutes={genderedSalutes}
          gmailMailboxId={this.props.gmailMailboxId}
          renderTemplateVariables={this.props.renderTemplateVariables}
          renderPreview={this.props.renderPreview}
          allowEditingFromStage={0}
          customPromptVariables={this.props.customPromptVariables}
          errorMessagesDescriptive={ERROR_MESSAGES}
          errorMessagesYesNo={ERROR_MESSAGES_YES_NO}
          manuallyRequestedLinkedInOutreach={this.props.manuallyRequestedLinkedInOutreach}
          onMessagesChange={this.props.onMessagesChange}
          ignoredMessageValidations={this.props.ignoredMessageValidations}
          handleIgnoredMessageValidationsChange={this.handleIgnoredMessageValidationsChange.bind(this)}
          campaignId={messages.campaignId}
          prefillMessages={this.props.prefillMessages}
          validating={this.props.validating}
          index={-1}
        />

        { this.renderFollowUps() }

        <Button type="button"
          onClick={this.handleAddFollowUp.bind(this)}
          icon="add"
          labelPosition="left"
          content={"Add follow up"}
        />
      </div>
    );
  }
}




