import React from "react";
import { Dropdown, Popup, } from 'semantic-ui-react';
export default class CopyToClipboardDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      copySuccess: false,
    }

    this.timeout = null;
    this.handleOnChange = this.handleOnChange.bind(this)
  }
  handleOnChange(e, data) {
    let value = data.value
    if (this.props.openLinks) {
      if (value.startsWith("http")) {
        window.open(value, '_blank')
      }
    }
    clearTimeout(this.timeout)
    navigator.clipboard.writeText(data.value);
    this.setState({ copySuccess: true })
    this.timeout = setTimeout( () => {
      this.setState({ copySuccess: false })
    }, 2000);

  }

  render () {
    const { openLinks, options, ...restProps } = this.props;

    let optionsWithDescriptions = options;
    if (openLinks) {
      optionsWithDescriptions = (options).map(v => {
        return {
          ...v,
          description: v.value.startsWith("http") ? "Open & Copy" : "Copy",
        }
      });
    }

    return (
      <React.Fragment>
        <Popup
          content='Copied!'
          open={this.state.copySuccess}
          flowing
          position={"top center"}
          style={{ width: 70 }}
          inverted
          trigger={
            <Dropdown
              { ...restProps }
              onChange={this.handleOnChange}
              options={optionsWithDescriptions}
              style={this.props.dropdownStyle}
            />
          }
        />
      </React.Fragment>
    )
  }
}
