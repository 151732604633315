import React from "react";
import { present, errorFor, selectGenderedSalutes } from "../../shared/utils.js";
import { TextArea, Form, Button, Dropdown, Grid, Segment, Icon, Message, Header, Progress, Loader, Label, Popup, Input, Checkbox } from 'semantic-ui-react';
import PlaintextOrWysiwygEditor from "./PlaintextOrWysiwygEditor.js"
import MessagePreview from "./MessagePreview.js"

export default class EditorWithTemplateVarsAndPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tookFocus: false,
    }

    this.handlePlaintextStateChange = this.handlePlaintextStateChange.bind(this);
  }

  plaintextState() {
    return this.props.model[this.props.name];
  }

  handlePlaintextStateChange(newState) {
    const { value, } = newState;

    this.props.onChange({
      target: {
        value,
        name: "message",
      }
    })
  }

  render() {
    let { asPlaintext, genderedSalutes, model, name, labelContent, renderTemplateVariables, highlightErrors } = this.props
    let error = errorFor(model, name, true)

    let mappedSalutes = selectGenderedSalutes(genderedSalutes)

    let variables = []
    if (renderTemplateVariables) {
      variables = variables.concat(mappedSalutes);
    }
    if (this.props.customPromptVariables && this.props.editable) {
      variables = variables.concat(this.props.customPromptVariables.sort((a, b) => (a.shortcut > b.shortcut) ? 1 : -1))
    }
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={this.props.renderPreview ? 8 : 16}>
            <Form.Field error={present(error)}>
              <label>{labelContent}</label>
              <PlaintextOrWysiwygEditor
                variables={variables}
                name={name}
                onPlaintextStateChange={this.handlePlaintextStateChange}
                plaintextState={this.props.model[this.props.name]}
                editable={this.props.editable}
                maxChars={this.props.maxChars}
                renderPreview={this.props.renderPreview}
                handleInsertTemplateVar={this.handleInsertTemplateVar}
                followUp={this.props.followUp}
              />
              {present(error) && <Label pointing prompt>{error}</Label>}
            </Form.Field>
          </Grid.Column>
          {this.props.renderPreview &&
            <Grid.Column width={8}>
              <MessagePreview
                ourName={this.props.ourName}
                ourShortFirstName={this.props.ourShortFirstName}
                asPlaintext={asPlaintext}
                template={this.plaintextState()}
                gmailMailboxId={this.props.gmailMailboxId}
                subject={this.props.model.subject}
                highlightErrors={highlightErrors}
                errors={this.props.errors}
                errorMessages={this.props.errorMessages}
                ignoredMessageValidations={this.props.ignoredMessageValidations}
              />
            </Grid.Column>
          }
        </Grid>
      </React.Fragment>
    );
  }
}
