import React from "react";
import { present } from "../../shared/utils.js";
import TextAreaWithInsertableVariables from "../../components/TextAreaWithInsertableVariables.js";

export default class PlaintextOrWysiwygEditor extends React.Component {
  constructor(props) {
    super(props)
    this.handleTextAreaActivity = this.handleTextAreaActivity.bind(this)
  }

  handleTextAreaActivity(event, data, cb) {
    this.props.onPlaintextStateChange({
      value: event.target.value,
    }, cb)
  }

  render() {
    let { name, onFocus, style, renderPreview, followUp } = this.props;
    let messageLength = this.props.plaintextState ? this.props.plaintextState.length : 0;

    return (
      <React.Fragment>
        <div>
          <TextAreaWithInsertableVariables
            variables={this.props.variables}
            onFocus={onFocus}
            style={{ minHeight: 300, ...style }}
            value={this.props.plaintextState}
            onChange={this.handleTextAreaActivity.bind(this)}
            name={name}
            disabled={!this.props.editable}
          />
          <div>
            {!renderPreview && !followUp && (
              <p
                style={{
                  float: 'left',
                  color: messageLength > this.props.maxChars && 'red',
                  marginTop: 8,
                  lineHeight: '10px'
                }}
              >
                {present(this.props.maxChars) && <span>{messageLength} / {this.props.maxChars}</span>}
              </p>
            )}
          </div>

        </div>
      </React.Fragment>
    );
  }
}
