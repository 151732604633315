import React from "react";

import moment from "moment";
import { Grid, Icon, Message, Modal } from 'semantic-ui-react';
import { present, renderProspectCampaignAssocForTableView, } from "../shared/utils.js";
import axios from "axios";
import ModalView from "./ProspectView/ModalView.js";
import ColdCallingCardView from "./ProspectView/ColdCallingCardView.js";
import Attachment from "../components/Chat/Conversation/Attachment.js";

export default class ProspectView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      crmTasks: [],
      phoneCalls: [],
      loading: false,
      tasksDidLoadOnce: false,
      prospect: { prospectCampaignAssociations: [], manuallyRequestedLinkedInOutreaches: [], triggeredManuallyRequestedLinkedInOutreaches: [], tagIds: [] },
      tags: props.tags || [],
      addDataProviderInformation: {},
      activePopup: null,
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.onNewTaskSaved = this.onNewTaskSaved.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onProspectChangeWithoutNotes = this.onProspectChangeWithoutNotes.bind(this);
    this.onCrmTaskRefreshed = this.onCrmTaskRefreshed.bind(this);
    this.loadProspect = this.loadProspect.bind(this);
    this.onManuallyRequestedLinkedInOutreachChange = this.onManuallyRequestedLinkedInOutreachChange.bind(this)
    this.onManuallyRequestedLinkedInOutreachAdded = this.onManuallyRequestedLinkedInOutreachAdded.bind(this)
    this.onManuallyRequestedLinkedInOutreachRemoved = this.onManuallyRequestedLinkedInOutreachRemoved.bind(this)
    this.renderReply = this.renderReply.bind(this)
    this.fetchData = this.fetchData.bind(this);
    this.abortController = null;
  }

  handleOpen() {
    this.loadTasks();
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      this.fetchData();
    }

    if (prevState.prospect.id && Array.isArray(this.state.prospect.tagIds) && Array.isArray(prevState.prospect.tagIds) && prevState.prospect.tagIds.length != this.state.prospect.tagIds.length) {
      this.updateProspectTags(this.state.prospect.tagIds)
    }
  }

  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  fetchData() {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();
    const { signal } = this.abortController;

    this.loadProspect(false, signal);
    this.loadTasks(signal);
  }

  renderCompanyLink(prospect) {
    const frontendCompanyUrl = (url) => {
      return url.replace("/sales", "")
    }

    var name = present(prospect.primaryCompanyName) ? prospect.primaryCompanyName : ""
    if (present(prospect.primaryCompanyLinkedinUrl)) {
      return (
        <a href={frontendCompanyUrl(prospect.primaryCompanyLinkedinUrl)} target="_blank">{name}</a>
      )
    } else {
      return (
        <React.Fragment> {name} </React.Fragment>
      )
    }
  }

  handlePopupActivation = (popupId) => {
    this.setState({ activePopup: popupId });
  };

  onProspectFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const toChange = { ...this.state.prospect }
    toChange[name] = value
    this.setState({ prospect: toChange })
    this.props.onProspectChange(toChange)
  }

  onProspectChangeWithoutNotes(prospect) {
    let notesPreservedProspect = { ...prospect, notes: this.state.prospect.notes }
    this.setState({ prospect: notesPreservedProspect })
    this.props.onProspectChange(notesPreservedProspect)
  }

  renderManuallyCreatedAssoc(assoc, users) {
    let user = this.userWithId(assoc.linkedInOutreach.createdByUserId, users)
    return <React.Fragment>
      Manually created at: {this.l(assoc.manually_created_at)}<br />
      Manually created by: {user ? user.email : "unknown"}
    </React.Fragment>
  }

  renderNormallyCreatedAssoc(assoc, users, prospect) {
    let manuallyRequestedLinkedInOutreach = prospect.manuallyRequestedLinkedInOutreaches.find(o => o.linkedInOutreach && o.linkedInOutreach.id === assoc.linkedInOutreach.id);
    return (
      <React.Fragment>
        { manuallyRequestedLinkedInOutreach && <React.Fragment><span>This outreach was created by a <a href={"/manually_requested_linked_in_outreaches/"+manuallyRequestedLinkedInOutreach.id}>manual connection request</a>.</span><br /></React.Fragment> }
        Sent request at: { this.l(assoc.linkedInOutreach.sentConnectionRequestAt) }<br />
        Replied at: { present(assoc.linkedInOutreach.repliedAt) ? this.l(assoc.linkedInOutreach.repliedAt) : "-" }
      </React.Fragment>
    );
  }

  renderReply(assoc, prospect) {
    if (assoc.linkedInOutreach.repliedVia === "email") {
      return (<span style={{ display: "flex" }}>
        Email:&nbsp;
        {renderProspectCampaignAssocForTableView(assoc, prospect, 1, "right", this.handlePopupActivation, this.state.activePopup) }
      </span>);
    }
    if (assoc.linkedInOutreach.threadUrl) {
      return (
        <span style={{ display: "flex" }}>
          Thread:&nbsp;
          {renderProspectCampaignAssocForTableView(assoc, prospect, 1, "right", this.handlePopupActivation, this.state.activePopup)}
        </span>
      );
    }
    return null;
  }

  renderAssoc(assoc, prospect, i, users) {
    if (!present(assoc.linkedInOutreach)) return null;

    let anyAttachments = assoc.linkedInOutreach.linkedInOutreachThreads.reduce((acc, thread) => {
      return acc || thread.attachments.length > 0
    }, false)

    let noRenderedAttachments = 0;

    return (
      <div key={i}>
        { present(assoc.linkedInOutreach.manuallyCreatedAt) ? this.renderManuallyCreatedAssoc(assoc, users) : this.renderNormallyCreatedAssoc(assoc, users, prospect) }<br />
        { this.renderReply(assoc, prospect) }
        {anyAttachments > 0 && <>Attachments:&nbsp;</>}
        {assoc.linkedInOutreach.linkedInOutreachThreads.map((linkedInOutreachThread) => {
          return linkedInOutreachThread.attachments.map((a) => {
            noRenderedAttachments += 1
            return (
              <span style={{ display: 'inline-block'}}>
                {noRenderedAttachments > 1 && (<span>, &nbsp;</span>)}
                <Attachment
                  key={a.linkedInAttachmentId}
                  text={a.path}
                  downloadUrl={`/linked_in_threads/${linkedInOutreachThread.linkedInThread.id}/attachment/${a.path}`}
                  attachmentSize={a.size}
                  attachment={a.path}
                  showPreviewOnHover={true}
                  hideIcon={true}
                  hideSize={true}
                />
              </span>
            )
          })
        })}
      </div>
    )
  }

  onNewTaskSaved(crmTask) {
    this.setState({
      crmTasks: [crmTask, ...this.state.crmTasks, ],
    });
    if (present(crmTask.prospect)) {
      this.onProspectChangeWithoutNotes(crmTask.prospect)
    }
    this.props.onCrmTaskAdded && this.props.onCrmTaskAdded(crmTask)
  }

  loadProspect(callbackProspectData = false, signal = null) {
    this.setState({ loading: true })
    axios.get(`/prospects/${this.props.id}.json`, { signal })
      .then(({ data }) => {
        this.setState({
          prospect: data,
          phoneCalls: data.phoneCalls,
          loading: false,
          errorMessage: null,
        })

        if (callbackProspectData) {
          this.props.onProspectChange(data)
        }
      })
      .catch(error => {
        if (error.message === 'canceled') {
          console.log("Prospect fetch aborted to avoid concurrent requests");
        } else {
          this.setState({ loading: false, errorMessage: "Prospect not found" })
          console.error("an error occured loading prospect", error);
        }
      })
  }

  updateProspect() {
    let { prospect } = this.state
    if (!present(prospect.id)) { return }
    axios.post(`/prospects/${prospect.id}`, { id: prospect.id, notes: prospect.notes })
      .then(({data}) => {
        console.log("update success");
        console.log(data);
      })
  }

  loadTasks(signal = null) {
    this.setState({ loading: true })
    axios.get(`/crm_tasks.json?associated_with[]=${this.props.id}`, { signal })
      .then(({ data }) => {
        this.setState({
          loading: false,
          tasksDidLoadOnce: true,
          crmTasks: data.crmTasks,
        })
      })
      .catch(error => {
        if (error.message === 'canceled') {
          console.log("Task fetch aborted to avoid concurrent requests");
        } else {
          this.setState({ loading: false, })
          console.error("an error occured loading crmTasks", error);
        }
      })
  }

  onClose() {
    this.props.onClose && this.props.onClose();
    this.updateProspect();
  }

  onCrmTaskRefreshed(task) {
    this.loadTasks()
    if (present(task.prospect)) {
      this.onProspectChangeWithoutNotes(task.prospect)
    }
    this.props.onCrmTaskChange && this.props.onCrmTaskChange(task)
  }

  userWithId(id, users) {
    return users.find(u => u.id == id)
  }

  l(date) {
    if (!present(date)) return "-"
    return moment(date).format("DD.MM.YYYY")
  }

  lHour(date) {
    if (!present(date)) return "-"
    return moment(date).format("DD.MM.YYYY HH:mm")
  }

  onManuallyRequestedLinkedInOutreachChange(o) {
    this.loadProspect()
    this.props.onManuallyRequestedLinkedInOutreachChange && this.props.onManuallyRequestedLinkedInOutreachChange(o)

  }

  onManuallyRequestedLinkedInOutreachAdded(o) {
    this.loadProspect()
    this.props.onManuallyRequestedLinkedInOutreachAdded && this.props.onManuallyRequestedLinkedInOutreachAdded(o)
  }

  onManuallyRequestedLinkedInOutreachRemoved(o) {
    this.loadProspect()
    this.props.onManuallyRequestedLinkedInOutreachRemoved && this.props.onManuallyRequestedLinkedInOutreachRemoved(o)
  }

  handleTagCreation(tag) {
    axios.post('/prospects/tags', { name: tag })
      .then(({ data }) => {
        if (this.props.setTags) { this.props.setTags(data) }
        if (this.state.prospect.tagIds.find(t => t == data.find(t => t.name == tag).id)) {
          this.setState({
            tags: data
          })
        } else {
          this.setState({
            prospect: { ...this.state.prospect, tagIds: this.state.prospect.tagIds.concat(data.find(t => t.name == tag).id) },
            tags: data
          })
        }
      })
      .catch(error => {
        console.log("error when creating tag ", error);
      });
  }

  updateProspectTags(tagIds) {
    axios.post(`/prospects/tags/${this.state.prospect.id}`, { tagIds }).then(({ data }) => {
      if (this.props.setTags) {
        this.props.setTags(data)
      }
      this.setState({ tags: data })
    }).catch(error => {
      console.log(error)
    })
  }

  handleDataProviderInformationCreation(dataProviderInformation) {
    axios.post('/prospects/data_provider_information', { ...dataProviderInformation, prospectId: this.state.prospect.id })
      .then((response) => {
        let prospect = response.data
        this.setState({ addDataProviderInformation: {} })
        this.onProspectChangeWithoutNotes(prospect)
      })
      .catch(error => {
        console.error("error when creating data provider information ", error);
      });
  }

  handleDataProviderInformationDeletion(dataProviderInformation) {
    axios.delete(`/prospects/data_provider_information/${dataProviderInformation.id}?prospectId=${this.state.prospect.id}`)
      .then((response) => {
        let prospect = response.data
        this.setState({ addDataProviderInformation: {} })
        this.onProspectChangeWithoutNotes(prospect)
      })
      .catch(error => {
        console.error("error when deleting data provider information ", error);
      });
  }

  noErrorModalContent() {

  }

  errorModalContent() {
    return <Message
      header={this.state.errorMessage}
      content={"This prospect may have been deleted, or you don't have permission to view it. If you feel this prospect should be visible to you please contact us."}
    />
  }

  render () {
    let prospect = this.state.prospect

    let dataProviderInformation = prospect.dataProviderInformation || []
    dataProviderInformation = dataProviderInformation.filter((dpi, i) =>
      dataProviderInformation.findIndex(dpi2 => (dpi.value === dpi2.value)) === i
    )

    const handlers = {
      renderCompanyLink: this.renderCompanyLink,
      onProspectChangeWithoutNotes: this.onProspectChangeWithoutNotes,
      onProspectFormChange: this.onProspectFormChange.bind(this),
      onNewTaskSaved: this.onNewTaskSaved,
      renderAssoc: this.renderAssoc,
      renderManuallyCreatedAssoc: this.renderManuallyCreatedAssoc,
      renderNormallyCreatedAssoc: this.renderNormallyCreatedAssoc,
      renderReply: this.renderReply,
      userWithId: this.userWithId,
      l: this.l,
      lHour: this.lHour,
      onCrmTaskRefreshed: this.onCrmTaskRefreshed,
      onManuallyRequestedLinkedInOutreachAdded: this.onManuallyRequestedLinkedInOutreachAdded,
      onManuallyRequestedLinkedInOutreachChange: this.onManuallyRequestedLinkedInOutreachChange,
      onManuallyRequestedLinkedInOutreachRemoved: this.onManuallyRequestedLinkedInOutreachRemoved,
      setState: this.setState.bind(this),
      loadProspect: this.loadProspect.bind(this),
      handleDataProviderInformationCreation: this.handleDataProviderInformationCreation.bind(this),
      handleDataProviderInformationDeletion: this.handleDataProviderInformationDeletion.bind(this),
      handleTagCreation: this.handleTagCreation.bind(this),
      handlePopupActivation: this.handlePopupActivation.bind(this),
    };

    const data = {
      prospect: prospect,
      campaigns: this.props.campaigns,
      crmStatuses: this.props.crmStatuses,
      crmTasks: this.state.crmTasks,
      tags: this.state.tags,
      loading: this.state.loading,
      users: this.props.users,
      currentUserId: this.props.currentUserId,
      addDataProviderInformation: this.state.addDataProviderInformation,
      openCallSegment: this.state.openCallSegment,
      phoneCalls: this.state.phoneCalls,
      currentUserTwilioPhoneNumber: this.props.currentUserTwilioPhoneNumber,
      dataProviderInformation,
      activePopup: this.state.activePopup,
      tasksDidLoadOnce: this.state.tasksDidLoadOnce,
    }

    if (this.props.renderColdCallingCardView) {
      return (
        present(this.state.errorMessage) ? this.errorModalContent() : <ColdCallingCardView handlers={handlers} data={data} />
      )
    }

    return (

      <React.Fragment>

        <Modal open={true} onClose={this.onClose}>
          <Modal.Header>
            <Grid>
              <Grid.Column width={15}>
                { present(prospect.linkedInProfileUrl) && <a href={prospect.linkedInProfileUrl} target="_blank" className="ui" aria-label="Profile"> <i className="fab fa-linkedin icon" style={{fontFamily: "'Font Awesome 6 Brands'", color: "#2E384D"}}></i> </a> }
                { prospect.name }
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon name="close" onClick={this.onClose} style={{cursor: "pointer"}}/>
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
             { present(this.state.errorMessage) ? this.errorModalContent() : <ModalView handlers={handlers} data={data} /> }
          </Modal.Content>

        </Modal>
      </React.Fragment>

    )
  }
}
